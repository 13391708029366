<template>
    <div>
        <Loading v-if="$global.state.loading" />

        <v-row align="center" class="mb-3">
            <v-col cols="12" md="4">
                <v-btn
                    v-if="!haveProducts"
                    @click="openAddDialog()"
                    color="primary"
                    large
                    class="btn secondary--text"
                >
                    <v-icon class="ml-2">add_circle_outline</v-icon>
                    <h3>إضافة قسم</h3>
                </v-btn>
                <v-btn
                    v-if="$global.state.categories == '' || haveProducts"
                    @click="openAddProductDialog()"
                    color="primary"
                    large
                    class="mr-2 btn secondary--text"
                >
                    <v-icon class="ml-2">add_circle_outline</v-icon>
                    <h3>إضافة منتج</h3>
                </v-btn>
            </v-col>
            <v-spacer />
            <v-col cols="12" md="4" class="d-flex">
                <v-text-field
                    v-debounce:500ms="getItems"
                    v-model="$global.state.filter.search"
                    label="ابحث هنا"
                    color="info"
                    hide-details
                    prepend-inner-icon="search"
                    dense
                    outlined
                    filled
                />
                <v-btn @click="getPreviousCategory" icon class="btn mr-2">
                    <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
            </v-col>
        </v-row>

        <!-- no categories  -->
        <h4 class="text-center grey--text mt-16" v-show="$global.state.categories == '' ">
            <v-icon x-large>fi fi-rr-shield-exclamation</v-icon>
            <p class="mt-2" v-if="this.$global.state.parentId == null">لا توجد اقسام</p>
            <p class="mt-2" v-else>لا توجد اقسام فرعية</p>
        </h4>

        <!-- categories -->
        <v-row v-if="!haveProducts">
            <v-col cols="12" lg="2" md="3" sm="6" v-for="(item, i) in $global.state.categories" :key="i" @click="getSubCategory(item)"> 
                
                <v-card flat class="shadow animation-card cursor-pointer">
                    <v-img :src="item.imageUrl ? item.imageUrl : '@/assets/img/logo2.svg'" height="100">
                        <v-img v-if="!item.imageUrl" class="catg-image" src="@/assets/img/logo2.svg" height="100"></v-img>
                        <v-app-bar dense flat color="rgba(0, 0, 0, 0)">
                            <v-menu bottom origin="center center" transition="scale-transition">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn x-small fab depressed v-bind="attrs" v-on="on">
                                        <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item @click="openEditDialog(item)">
                                        <v-list-item-title>
                                            <v-icon color="info">edit</v-icon>
                                            <span class="mx-2">تعديل</span>
                                        </v-list-item-title>
                                    </v-list-item>

                                    <v-list-item @click="deleteItem(item.id, item.index)">
                                        <v-list-item-title>
                                            <v-icon color="error">delete</v-icon>
                                            <span class="mx-2">حذف</span>
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-app-bar>
                    </v-img>

                    <div>
                        <div class="catg-name" :class="item.parentId == null ? 'primary' : 'grey lighten-1'">
                            <p class="catg-name-txt">{{ item.parentId == null ? "رئيسي" : "فرعي" }}</p>
                        </div>

                        <div class="py-2 px-3">
                            <h3 v-text="item.name" class="mb-2 mt-1"></h3>
                            <div class="grey--text d-flex justify-space-between">
                                <div>{{ new Date(item.created).toISOString().substring(0, 10) }}</div>
                                <div>
                                    <v-icon small class="mt-n1">fi fi-br-shopping-bag</v-icon>
                                    <span class="primary--text text--darken-1 mr-1">{{ item.products.length }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </v-card>
            </v-col>
        </v-row>

        <!-- products -->
        <v-container>
            <v-row v-if="haveProducts">
                <v-col
                    cols="12"
                    md="3"
                    sm="6"
                    v-for="(item, index) in $global.state.products"
                    :key="index"
                >
                    <v-card
                        flat
                        class="shadow animation-card cursor-pointer"
                        @click="getDetails(item)"
                    >
                        <v-img
                            :src=" item.mainImageUrl ? item.mainImageUrl : '@/assets/img/logo2.svg' "
                            height="130"
                        >
                            <v-img
                                v-if="!item.mainImageUrl"
                                src="@/assets/img/logo2.svg"
                                height="100"
                            ></v-img>
                            <v-app-bar dense flat color="rgba(0, 0, 0, 0)">
                                <v-menu bottom origin="center center" transition="scale-transition">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            x-small
                                            fab
                                            depressed
                                            v-bind="attrs"
                                            v-on="on"
                                            style="opacity: .9;"
                                        >
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item @click="openEditProductDialog(item)">
                                            <v-list-item-title>
                                                <v-icon color="info">edit</v-icon>
                                                <span class="mx-2">تعديل</span>
                                            </v-list-item-title>
                                        </v-list-item>

                                        <v-list-item @click="deleteProduct(item.id, index)">
                                            <v-list-item-title>
                                                <v-icon color="error">delete</v-icon>
                                                <span class="mx-2">حذف</span>
                                            </v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </v-app-bar>
                        </v-img>

                        <v-card-subtitle>
                            <v-chip x-small color="info" class="mr-n1" v-text="item.categoryName"></v-chip>
                            <h3 v-text="item.name" class="font-weight-bold my-2"></h3>
                            <div
                                class="my-2"
                            >{{ item.description.length > 40 ? item.description.substring(0, 40) + " ..." : item.description }}</div>
                            <div class="grey--text d-flex justify-space-between">
                                <div>{{ item.created | formatDate }}</div>
                                <div>
                                    <span
                                        class="primary--text text--darken-1 ml-1"
                                    >{{ item.sizes.length }}</span>
                                    <small>قياسات</small>
                                </div>
                            </div>
                        </v-card-subtitle>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>

        <DialogProducts />
        <Dialog />
    </div>
</template>

<script>
export default {
    components: {
        Dialog: () => import("./dialog"),
        DialogProducts: () => import("../products/dialog"),
    },
    data() {
        return {
            haveProducts: false,
            parentId: null,
        };
    },

    mounted() {
        this.$global.state.parentId = null;
        this.getItems();
        this.$eventBus.$on(`refresh`, () => {
            this.getItems();
        });
        this.$eventBus.$on(`refreshProducts`, () => {
            this.haveProducts = true;
            this.getProducts();
        });
    },

    beforeDestroy() {
        this.$eventBus.$off(`refresh`);
        this.$eventBus.$off(`fill-fields`);
        this.$eventBus.$off(`fill-fields-product`);
    },

    methods: {
        getItems() {
            this.$global.dispatch(`get${this.$route.meta.endPoint}`);
        },

        async getSubCategory(item) {
            this.$global.state.categoryId = item.id;
            this.parentId = item.parentId;
            if (!item.haveProducts) {
                this.$global.state.parentId = item.id;
                await this.getItems();
            } else {
                this.haveProducts = true;
                this.$global.state.products = item.products;
                // // console.log(this.$global.state.products);
                // this.getProducts();
                // this.$router.push("/products");
            }
        },

        async getPreviousCategory() {
            this.$global.state.parentId = null;
            if (this.parentId == null || this.$global.state.categories == "") {
                this.getItems();
                // console.log("if");
            } else {
                (await this.$global.state.catgId) ==
                    this.$global.state.categories[0].parentId;
                this.haveProducts = false;
                this.getItems();
                // console.log("else");
                // console.log(this.$global.state.categories[0].parentId);
            }
        },

        getProducts() {
            this.$global.dispatch(`getProducts`, this.$route.meta.role);
        },

        async deleteItem(id, i) {
            this.$genericService.swalAlertConfirm({}).then((result) => {
                if (result.isConfirmed) {
                    this.$genericService
                        .delete(`${this.$route.meta.endPoint}`, id)
                        .then((res) => {
                            this.$store.dispatch("toastification/setToast", {
                                message: `${res.data.message}`,
                                type: "success",
                            });
                            this.$global.state.categories.splice(i, 1);
                        })
                        .catch((err) => {
                            this.$store.dispatch("toastification/setToast", {
                                message: `${err.data.message}`,
                                type: "error",
                            });
                        });
                }
            });
        },

        openAddDialog() {
            var item = {
                dialogType: "add",
            };
            this.$store.commit("setItemDetails", item);
            this.$store.commit("setDialog");
        },

        openEditDialog(item) {
            item.dialogType = "edit";
            this.$store.commit("setItemDetails", item);
            this.$store.commit("setDialog");
            this.$eventBus.$emit("fill-fields");
        },

        // ****************************** products ******************************
        openAddProductDialog() {
            var item = { dialogType: "add" };
            this.$store.commit("setItemDetails", item);
            this.$store.commit("setProductDialog");
        },

        openEditProductDialog(item) {
            item.dialogType = "edit";
            this.$store.commit("setItemDetails", item);
            this.$store.commit("setProductDialog");
            this.$eventBus.$emit("fill-fields-product");
        },

        async deleteProduct(id, i) {
            this.$genericService.swalAlertConfirm({}).then((result) => {
                if (result.isConfirmed) {
                    this.$genericService
                        .delete(`Products`, id)
                        .then((res) => {
                            this.$store.dispatch("toastification/setToast", {
                                message: `${res.data.message}`,
                                type: "success",
                            });
                            this.$global.state.products.splice(i, 1);
                        })
                        .catch((err) => {
                            this.$store.dispatch("toastification/setToast", {
                                message: `${err.data.message}`,
                                type: "error",
                            });
                        });
                }
            });
        },

        getDetails(item) {
            this.$router.push({
                name: "productDetails",
                params: {
                    obj: item,
                },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.catg-image {
    position: absolute;
    right: 20%;
    opacity: 0.7;
}

.catg-name {
    position: absolute;
    top: 100px;
    left: 0;
    color: black !important;
    width: 110px;
    height: 100px;
    -webkit-clip-path: polygon(45% 1%, 0 0, 0 46%);
    clip-path: polygon(45% 0%, 0 0, 0 45%);
}

.catg-name-txt {
    position: absolute;
    rotate: -45deg;
    top: 7px;
    left: 0;
    font-size: 12px;
}

.card-product-category {
    position: absolute;
    top: 0;
    left: 0;
    color: white;
    border-radius: 6px;
    width: 130px;
    height: 130px;
    -webkit-clip-path: polygon(45% 1%, 0 0, 0 46%);
    clip-path: polygon(45% 0%, 0 0, 0 45%);
}
.card-product-category-txt {
    position: absolute;
    rotate: -45deg;
    top: 10px;
    left: 0px;
    text-align: center;
    font-size: 8px;
}
</style>
